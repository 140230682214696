import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import logo from '../../../assets/pngs/logo.png'
import {CustomButton} from '../../../components/Button/Button.styles'
import {Link} from 'react-router-dom'

import { Tabs } from 'antd';

const { TabPane } = Tabs;


const LogoArea = styled.div`

`
const CustomInput = styled.input`
height: 50px;
background: #FFFFFF;
border: 1px solid #042A2B;
box-sizing: border-box;
padding: 10px;
margin: 10px
`
const CustomBtn = styled(CustomButton)`
Background: black;
&:hover, &:focus, &:visited, &:disabled, &:active{
    background-color: black;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    transition: box-shadow 700ms;
};
width: 300px
`

const Login = props => {
    return (
        <>
        <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col md={12} style={{textAlign: "center", paddingTop: "50px", paddingBottom: "50px"}}>
                <img src={logo} />
            </Col>
            <Col md={12}></Col>
            <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                <Row style={{background: "", width: "100%", height: "100%"}} className="d-flex flex-column justify-content-center align-items-center">
                <Col style={{background: "", width: "100%", textAlign: "center"}} md={5}  >
                    <div style={{padding: "30px",  textAlign: "center"}}>
                <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>Welcome!</div>
                <div>Login to continue</div>
                </div>
                <div className="d-flex flex-column">
                <CustomInput placeholder="Email Address" type="text" name="" required="" />
                <CustomInput placeholder="Password" type="password" name="" id="password" required="" />
                
                </div>
                <div>
                    <div>
                    </div>
                    <div>Remember Me</div>
                </div>
                <div>
                Forgot my password, <Link style={{color: "#5EB1BF"}} as={Link} to="/password-reset">Reset here</Link>
                </div>

                <div style={{margin: "30px"}}>
                    <Link to={'/plan/create/1'}>
                         <CustomBtn>Login </CustomBtn>
                    </Link>
                    
                </div>

                <div>
                    I’m new here, <Link style={{color: "#5EB1BF"}} as={Link} to="/register">Create account</Link> 
                </div>
               
                </Col>
                </Row>
               

            </Col>
        </Row>
        </>
    )
}

export default Login 