import { Container, Col, Row } from 'react-bootstrap'
import styled from "styled-components"
import DashboardNavBar from '../../../components/DashboardNavBar'
import DashboardSidebar from '../../../components/DashboardSideBar'
import {
    BrowserRouter as Router,
    useRouteMatch,
    Switch,
  Route,
  Link,
  } from "react-router-dom"
import SummaryPage from '../summaryPage'
import QuickSavePage from '../quickSavePage'
import SavingsPage from '../savingsPage'
const DashboardContainer = styled(Container)`
height: 100%;
background:#FCFCFC
`



const DashboardWrapper = props => {

    let { path, url } = useRouteMatch();
    console.log(path)
    return (
        <DashboardContainer  fluid>
           
        <div  style={{height: "100%"}}>
            <Row>
            <Col>
            <DashboardNavBar/>
            </Col> 
            </Row>
            <Row style={{height: "100%"}}>
            <Col md={2}>
                 <DashboardSidebar/>
            </Col>
            <Col md={10}>
              <Switch>
                    <Route exact path={path}>
                        <SummaryPage />
                    </Route>
                    <Route exact path={`${path}/quick-save`}>
                    <QuickSavePage/>
                    </Route>
                    <Route exact path={`${path}/savings`}>
                    <SavingsPage/>
                    </Route>
            </Switch>
            </Col>
            </Row>
            
        </div>
      
        </DashboardContainer>
    )
}

export default DashboardWrapper
