
import {Col, Row } from 'react-bootstrap'
import styled from "styled-components"
import Add from "../../../assets/svgs/dashboard/add.svg"
import Youth from "../../../assets/svgs/dashboard/youth.svg"
import steady from "../../../assets/svgs/dashboard/steady.svg"
const SummaryCard = styled.div`
background: #424CA0;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 5px;
width: 100%;
height: 250px;
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 50px;
`
const WelcomeText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
color: #D8D8D8;
`

const AccountText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
color: #FFFFFF;
`
const AccountSubText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #EF7B45;
padding-top: 15px
`
const Trans = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 14px;
color: #A1A1A1;
`

const BodyTextContent = styled.div`
width: 134px;
height: 42px;
font-family: Roboto;
font-style: normal;
font-weight: 300;
font-size: 12px;
color: #042A2B;
`

const Body = styled.div`
padding: 50px;
display: flex;
flex-direction: column
`


const TrasactionContainer = styled.div`
padding: 30px;
`
const NoTransCard = styled.div`
background: #FFFFFF;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 5px;
height: 74px;
display: flex;
justify-content: center;
align-items: center;
`

const NoTransCardText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
color: #A2BCC3;
`

const BodyCard = styled.div`
width: 164px;
height: 164px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(240, 240, 240, 0.25);
border-radius: 6px;
padding: 10px;
margin-right: 20px
`

const Bodytext = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: #042A2B;
`

const SummaryPage = props => {
    return (
        <> 
        <Row>
            <Col md={8}>
                <SummaryCard>
                    <WelcomeText>Welcome, <span style={{fontWeight: "bolder"}}>Grace</span></WelcomeText>
                    <div  className="d-flex justify-content-between">
                        <div>
                            <AccountText>You have no savings yet!</AccountText>
                            <AccountSubText>Click the Button to add a savings plan</AccountSubText>
                        </div>
                        <div>
                            <img src={Add} />
                        </div>
                    </div>
                </SummaryCard>
                <Body>
                    <div style={{color: "#424CA0", paddingBottom: "20px"}}>Save Now!</div>
                    <div className="d-flex">
                    <BodyCard>
                       <div style={{marginBottom: "10px"}}>
                           <img src={Youth} />
                       </div>
                       <Bodytext>Youth Goals</Bodytext>
                       <BodyTextContent>
                       Start a savings pla that helps you achieve all your big plans 
                       </BodyTextContent>
                   </BodyCard>
            
                   <BodyCard>
                       <div style={{marginBottom: "10px"}}>
                           <img src={steady} />
                       </div>
                       <Bodytext>Youth Goals</Bodytext>
                       <BodyTextContent>
                       Start a savings pla that helps you achieve all your big plans
                       </BodyTextContent>
                   </BodyCard>
                    </div>
                  
               </Body>
                
            </Col>
            <Col md={4}>
                <TrasactionContainer>
                     <Trans>Transaction History</Trans>
                     <div style={{marginTop:"30px"}}> 
                     <NoTransCard>
                    <NoTransCardText>
                    You have no transaction history yet, <span style={{fontWeight:"bold", color: "#424CA0" }}>Add funds</span>
                    </NoTransCardText>
                </NoTransCard>
                </div>
                </TrasactionContainer>
            </Col>
        </Row>
        </>
    )
   
}


export default SummaryPage 