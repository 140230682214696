import SideDisplay from "../../components/SideDisplay"
import { Container, Col, Row } from 'react-bootstrap'
import Logo from '../../assets/pngs/lightLogo.png'
import styled from "styled-components"
import { CustomButton } from "../../components/Button/Button.styles"
import Arrowhead from "../../assets/pngs/arrow-head.png"
import Piggy from "../../assets/pngs/piggy.png"
import BackArrow from "../../assets/pngs/backArrow.png"
import { useState } from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
const Content = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%
`
const TopLabel = styled.div`
width: 100%;
height: 29px;
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
color: #042A2B;
`
const MainContent = styled.div`
padding: 10%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between
`
const CustomToggleButton = styled(ToggleButton)`
&.btn-primary{
    color: black;
    background-color: #F8F8F8;
    border-color: #F8F8F8;
    box-shadow: none;
    min-width: 120px;
    margin-right: 20px;
    padding-right: 10px;
    padding-left: 10px;
    height:78px;
    display: flex;
    justify-content: center;
    align-items: center
};
&.btn-primary:not(:disabled):not(.disabled).active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #424CA0;
    border-color: #424CA0;
}
`

const Label = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
color: #D8D8D8;
`

const ContentDesc = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 25px;
text-align: center;
color: #3E3E3E;
width: 50%;
margin-top: 30px;
`

const PageSix = props => {

    const [radioValue, setRadioValue] = useState('2');

    const handleChange = (val) => setRadioValue(val);

    const radios = [
        { name: 'Daily', value: '1' },
        { name: 'Weekly', value: '2' },
        { name: 'Monthly', value: '3' },
        
      ];

    return (
        <>
        <Row style={{height: "100vh", width: "100vw"}}>
            <Col md={5} style={{background: '#FFF8F5'}}>
                    <Content>
                     <img src={Piggy} style={{height:"450px"}} />
                     <ContentDesc>
                     Saving ₦200 Daily would make a trip to the tropicana possible by November 25, 2021 
                     </ContentDesc>
                     </Content>
            </Col>
            <Col md={7}>
                    <MainContent>
                        <div style={{ marginTop: "10%",}}>
                            <CustomButton color="#FFFFFF">
                            <img src={BackArrow} />
                             <span style={{color: "#000000", fontWeight:"bold"}}>  Back</span>
                            </CustomButton>
                       
                     
                    <div style={{ marginTop: "5%"}} >
                    <TopLabel style={{marginBottom: "30px"}}>
                        How often would you like to save into this account?
                    </TopLabel>
                    <Label style={{marginBottom: "40px"}}>Select Frequency</Label>
                    <ButtonGroup toggle>
                    {radios.map((radio, idx) => (
                        <CustomToggleButton  key={idx} 
                        type="radio"
                        value={radio.value} 
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >{radio.name}</CustomToggleButton >
                    ))}
                    </ButtonGroup>

                    
                    </div>  
                    <div style={{marginTop: "20%"}}>
                        <Link to={'/plan/create/7'}>
                        
                        <CustomButton style={{padding: "10px", width: "150px"}} color={'#000000'} > Next <img src={Arrowhead} /> </CustomButton>
                    </Link></div>
                    <div style={{marginTop: "5%", color: "#313131", fontSize: "14px", fontWeight: "300"}}> 
                    You can change selection later. </div>
                   
                    </div>
                    
                     </MainContent>
            </Col>
        </Row>  
        </>
    )
}

export default PageSix