import React from 'react'
import { Col, Row, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'

import light_logo from '../../assets/pngs/lightLogo.png'
import person from '../../assets/svgs/person.svg'
import arrow_down from '../../assets/svgs/arrow_down.svg'
import {Link} from "react-router-dom"
import { NavTabs } from '../../components/CustomTabs/CustomTabs';

const { TabPane } = NavTabs;

const CustomNavbar = props => {
    return (
            <Row>
                <Col md={1}></Col>
                <Col md={10} >
                   <Navbar expand="lg" style={{background: ""}}> 
                   <Row style={{background: "", width: "100%"}}>
                        <Col md={5} style={{background: ""}}>
                        <Navbar.Brand as={Link} to="/" >
                             <img src={light_logo} style={{width: "150px"}} />
                        </Navbar.Brand>
                        </Col>
                        <Col md={3}>
                        <Nav className="justify-content-between">
                        <Nav.Link as={Link} style={{color:"white"}} to="/" >Home</Nav.Link>
                        <Nav.Link as={Link} style={{color:"white"}} to="/savings-investment" >Savings Solutions</Nav.Link>
                        <Nav.Link as={Link} style={{color:"white"}} to="/faqs" >FAQ</Nav.Link>
                        </Nav>
                        </Col>
                        <Col as={Link} to="/login" md={4}>
                        <Nav className="justify-content-end align-items-center">
                            <img src={person} />
                            <div style={{paddingLeft:"5px", color:"white"}}>Account</div>
                            <img src={arrow_down} />
                        </Nav>
                        </Col>
                    </Row>
                    </Navbar>
                </Col>
                <Col md={1}></Col>
            </Row>
           
    )
}

export default CustomNavbar
