import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import logo from '../../../assets/pngs/logo.png'
import {CustomButton} from '../../../components/Button/Button.styles'
import {Link} from 'react-router-dom'
const LogoArea = styled.div`

`
const CustomInput = styled.input`
height: 50px;
background: #FFFFFF;
border: 1px solid #042A2B;
box-sizing: border-box;
padding: 10px;
margin: 10px
`
const CustomBtn = styled(CustomButton)`
Background: black;
&:hover, &:focus, &:visited, &:disabled, &:active{
    background-color: black;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    transition: box-shadow 700ms;

};
width: 300px
`

const PasswordReset = props => {
    return (
        <>
        <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col md={12} style={{textAlign: "center", paddingTop: "50px", paddingBottom: "50px"}}>
                <img src={logo} />
            </Col>
            <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                <Row style={{background: "", width: "100%", height: "100%"}} className="d-flex flex-column justify-content-center align-items-center">
                <Col style={{background: "", width: "100%", textAlign: "center"}} md={4}  >
                    <div style={{padding: "30px",  textAlign: "center"}}>
                <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>Reset Password</div>
                <div>Create a new password to access your account</div>
                </div>
                <div className="d-flex flex-column">
                <CustomInput placeholder="Password" />
                <CustomInput placeholder="Confirm Password" />
                </div>
                <div style={{margin: "30px"}}>
                    <CustomBtn>Reset Password</CustomBtn>
                </div>
                <div>Already have an account? 
                    <Link style={{color: "#5EB1BF"}} as={Link} to="/login">Login</Link>
                </div>
            </Col>
        </Row>
            </Col>
        </Row>
        </>
    )
}

export default PasswordReset