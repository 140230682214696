
import { Container, Col, Row, ButtonGroup, Button, ToggleButton } from 'react-bootstrap'
import styled from "styled-components"
import SidebarButton from '../SidebarBtn';
import { useState } from 'react'
import HomeIconLight from '../../assets/svgs/dashboard/sidebaricons/home_light.svg';
import HomeIconDark from '../../assets/svgs/dashboard/sidebaricons/home_dark.svg';
import SettingsIconLight from '../../assets/svgs/dashboard/sidebaricons/settings_light.svg';
import SettingsIconDark from '../../assets/svgs/dashboard/sidebaricons/settings_dark.svg';
import ProfileIconLight from '../../assets/svgs/dashboard/sidebaricons/profile_light.svg';
import ProfileIconDark from '../../assets/svgs/dashboard/sidebaricons/profile_dark.svg';
import SavingsIconLight from '../../assets/svgs/dashboard/sidebaricons/savings_light.svg';
import SavingsIconDark from '../../assets/svgs/dashboard/sidebaricons/savings_dark.svg';
import ToolsIconLight from '../../assets/svgs/dashboard/sidebaricons/tools_light.svg';
import ToolsIconDark from '../../assets/svgs/dashboard/sidebaricons/tools_dark.svg';
import HistoryIconDark from '../../assets/svgs/dashboard/sidebaricons/history_dark.svg';
import WithdrawalIconLight from '../../assets/svgs/dashboard/sidebaricons/withdrawal_light.svg';
import WithdrawalIconDark from '../../assets/svgs/dashboard/sidebaricons/withdrawal_dark.svg';
import Logout from '../../assets/svgs/dashboard/sidebaricons/logout.svg'
import { Link, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const Sidebar = styled.div`
background: #FFFFFF;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 5px;
padding: 30px;
padding-bottom: 30px;
display: flex;
align-items: center;
flex-direction: column;
height: 100%;
width: 100%
`
const CustomToggleButton = styled(ToggleButton)`

&.btn-primary{
    color: black;
    background-color: #F8F8F8;
    border-color: #F8F8F8;
    box-shadow: none;
    padding-right: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(240, 240, 240, 0.25);
    border-radius: 25px;
   
};
&.btn-primary:not(:disabled):not(.disabled).active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #424CA0;
    border-color: #424CA0;
};
`
const CustomButtonGroup = styled(ButtonGroup)`
&.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 25px;
};
&.btn-group-vertical>.btn:not(:first-child) {
    border-radius: 25px;
}
`

const CustomIconHolder = styled.div`
padding-right: 20px; 
padding-left: 20px; 
display: flex;
    align-items: center;
justify-content: flex-start
`

const CustomLink = styled(Link)`
text-decoration: none;
width: 100%;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`


const DashboardSidebar = props => {
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');
    let { path, url } = useRouteMatch();
    const radios = [
      { name: 'Summary', path:'', iconLight: HomeIconLight, iconDark: HomeIconDark,  value: '1' },
      { name: 'Quick Save', path:'/quick-save', iconLight: SavingsIconLight, iconDark: SavingsIconDark, value: '2' },
      { name: 'Savings', path:'/savings', iconLight: ProfileIconLight, iconDark: ProfileIconDark, value: '3' },
      { name: 'Withdrawal', path:'/withdrawal', iconLight: WithdrawalIconLight, iconDark: WithdrawalIconDark, value: '4' },
      { name: 'History', path:'/history', iconLight: HistoryIconDark, iconDark: HistoryIconDark, value: '5' },
      { name: 'Tools', path:'/tools', iconLight: ToolsIconLight, iconDark: ToolsIconDark, value: '6' },
      { name: 'Settings', path:'/settings', iconLight: SettingsIconLight, iconDark: SettingsIconDark, value: '7' },
    ];
  
    const history = useHistory();
    return (
        <>
        <Sidebar>
        <div style={{width: "100%"}}>
        <CustomButtonGroup toggle vertical style={{width: "100%",}}>
        {radios.map((radio, idx) => (
          <CustomToggleButton
            key={idx}
            type="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onClick={() => history.push(`${url}${radio.path}`)}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            
                <img style={{paddingRight: "20%"}} src={radioValue === radio.value ? radio.iconLight : radio.iconDark } />
           
            {radio.name} 
          </CustomToggleButton>
    
        ))}
        </CustomButtonGroup>
        </div>
        
        <div style={{marginTop: "50%"}}>
            Log out 
            <img src={Logout} />
        </div>
        
        </Sidebar>
       
        </>
    )
}


export default DashboardSidebar