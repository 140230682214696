import styled from "styled-components"


const DisplayContainer = styled.div`
height: 100%;
background: #E45200;

`

const SideDisplay = props => {
    return (
    <DisplayContainer>
        adadada
    </DisplayContainer>
    )
}

export default SideDisplay