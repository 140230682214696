import React from 'react'
import Description from '../../sections/LandingPage/description'
import Header from '../../sections/LandingPage/header'
import GetStarted from '../../sections/LandingPage/getStarted'
import Footer from '../../sections/LandingPage/footer'
import TestimonySection from '../../sections/LandingPage/testimony'
import HowSection from '../../sections/LandingPage/how'

const LandingPage = () => {
    return (
        <div>
            <Header />
            <HowSection />
            <Description/>
            <TestimonySection />
            <GetStarted />
            <Footer />
        </div>
    )
}

export default LandingPage
