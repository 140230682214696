import { Col, Row } from 'react-bootstrap'
import styled from "styled-components"
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import { useState } from 'react'
import Arrowhead from "../../../assets/pngs/arrow-head.png"
import Naira from "../../../assets/svgs/naira.svg"
import { CustomButton } from "../../../components/Button/Button.styles"
const SpecialInput = styled.div`
display: flex;
flex-direction: column;
`
const CustomInput = styled.input`
 ::placeholder { 
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 35px;
color: #D8D8D8; 
  };
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #000000; 
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 1px solid #000000;
width: 30%
`
const InputContent = styled.div`
display: flex;
flex-direction: row;
`
const Prefix = styled.div`
display: flex;
padding-right: 20px;
justify-content: center;
align-items: center;
font-family: Roboto;
font-size: 18px;
color: #8B8B8B;
`

const Label = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;

color: #475F6C;

`
const NavCard = styled.div`
width: 100%;
height: 85px;
background: #FFFFFF;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 6px;
padding: 20px;
display: flex;
justify-content: space-between;
align-items: center
`
const NavCardTitle = styled.div`
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
color: #042A2B;
`
const CustomToggleButton = styled(ToggleButton)`
&.btn-primary{
    color: black;
    background-color: #F8F8F8;
    border-color: #F8F8F8;
    box-shadow: none;
    min-width: 120px;
    margin-right: 20px;
    padding-right: 10px;
    padding-left: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center
};
&.btn-primary:not(:disabled):not(.disabled).active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #424CA0;
    border-color: #424CA0;
}
`
const QuickSavePage = props => {

    const radios = [
        { name: 'Miami Vacation', value: '1' },
        { name: 'New Car Savings 2021', value: '2' },
      ];

      const [radioValue, setRadioValue] = useState('1');

      const handleChange = (val) => setRadioValue(val);


    return (
    <> 
    <NavCard>
            <NavCardTitle>Quick Save</NavCardTitle>
            <div>
                <div style={{fontSize: "15px", fontWeight: "bolder"}}>One time savings</div>
                <div>Quick way to add funds to your plans</div>
            </div>
    </NavCard>

    <div style={{padding: "50px"}}>
        <div>
        </div>
        <div>
        <div style={{marginBottom: "20px"}}>
        <SpecialInput>
                        <Label>Amount(₦)</Label>
                        <InputContent>
                            <CustomInput placeholder="1,000"></CustomInput>
                        </InputContent>
                    </SpecialInput>
        </div>
        <div style={{marginBottom: "15px", color: '#475F6C'}}>Select Savings Plan</div>
        <ButtonGroup toggle>
                    {radios.map((radio, idx) => (
                        <CustomToggleButton  key={idx} 
                        type="radio"
                        value={radio.value} 
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >{radio.name}</CustomToggleButton >
                    ))}
         </ButtonGroup>
        </div>
        
        <div style={{marginTop: "20%"}}>
                        <CustomButton style={{padding: "10px", width: "150px"}} 
                        color={'#000000'} > Next 
                        <img src={Arrowhead} /> </CustomButton>
        </div>
    </div>
    </>
    )
    
}

export default QuickSavePage