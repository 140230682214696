import { Tabs } from 'antd';
import styled from "styled-components"
export const AntdTabs = styled(Tabs)`
margin-top: 50px;
@media (max-width: 460px) {
        margin-top: -15px;
    }
  &.ant-tabs {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
 & .ant-tabs-nav-wrap{
   padding-top: 20px;
 }
  &.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
    border-bottom: none
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: black;
    border: 2px solid #000000;
  }

  & .ant-tabs-tab{
    padding: 5px 0;
    color: #042A2B;
    font-weight: 500
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #042A2B;
    font-size: 17px;
  }

  & .ant-tabs-ink-bar{
    color: #042A2B;
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #042A2B;;
  }
`;

export const NavTabs = styled(AntdTabs)`
margin-top: 50px;
@media (max-width: 460px) {
        margin-top: -15px;
    }
  &.ant-tabs {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
 & .ant-tabs-nav-wrap{
   padding-top: 20px;
 }
  &.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
    border-bottom: none
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: black;
    border: 2px solid #000000;
  }

  & .ant-tabs-tab{
    padding: 5px 0;
    color: #042A2B;
    font-weight: 500
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #042A2B;
    font-size: 17px;
  }

  & .ant-tabs-ink-bar{
    color: #042A2B;
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #042A2B;;
  }
`;