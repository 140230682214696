import SideDisplay from "../../components/SideDisplay"
import { Container, Col, Row } from 'react-bootstrap'
import Logo from '../../assets/pngs/lightLogo.png'
import styled from "styled-components"
import { CustomButton } from "../../components/Button/Button.styles"
import Arrowhead from "../../assets/pngs/arrow-head.png"
import Bus from "../../assets/pngs/bus.png"
import BackArrow from "../../assets/pngs/backArrow.png"
import { useState } from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { DatePicker, Space } from 'antd';



const Content = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%
`
const TopLabel = styled.div`
width: 100%;
height: 29px;
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
color: #042A2B;
`
const MainContent = styled.div`
padding: 10%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between
`
const CustomToggleButton = styled(ToggleButton)`
&.btn-primary{
    color: black;
    background-color: #F8F8F8;
    border-color: #F8F8F8;
    box-shadow: none;
    min-width: 120px;
    margin-right: 20px;
    padding-right: 10px;
    padding-left: 10px
};
&.btn-primary:not(:disabled):not(.disabled).active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #424CA0;
    border-color: #424CA0;
}
`
const Label = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #042A2B;
padding-bottom: 10px
`

const PageFour = props => {

    const [radioValue, setRadioValue] = useState('1');

    const handleChange = (val) => setRadioValue(val);

    const radios = [
        { name: '3 Months', value: '1' },
        { name: '6 Months', value: '2' },
        { name: '1 Year', value: '3' }
      ];

    return (
        <>
        <Row style={{height: "100vh", width: "100vw"}}>
            <Col md={5} style={{background: '#FFF8F5'}}>
                    <Content>
                     <img src={Bus} style={{height:"450px"}} />
                     </Content>
            </Col>
            <Col md={7}>
                    <MainContent>
                        <div style={{ marginTop: "10%",}}>
                            <CustomButton color="#FFFFFF">
                            <img src={BackArrow} />
                             <span style={{color: "#000000", fontWeight:"bold"}}>  Back</span>
                            </CustomButton>
                       
                     
                    <div style={{ marginTop: "5%"}} >
                    <TopLabel style={{marginBottom: "30px"}}>
                     Do you want to save automatically?
                    </TopLabel>
                    <ButtonGroup toggle>
                    {radios.map((radio, idx) => (
                        <CustomToggleButton  key={idx} 
                        type="radio"
                        value={radio.value} 
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >{radio.name}</CustomToggleButton >
                    ))}
                    </ButtonGroup>
                    </div>  
                    </div>

                    <div>
                        <Label>I’ll pick my maturity date</Label>
                         <DatePicker style={{width: "40%"}} />
                    </div>
                    <div style={{marginTop: "20%"}}>
                        <Link to={"/plan/create/5"}>
                             <CustomButton style={{padding: "10px", width: "150px"}} color={'#000000'} > Next <img src={Arrowhead} /> </CustomButton>
                        </Link>
                       
                    </div>
                     </MainContent>
            </Col>
        </Row>  
        </>
    )
}

export default PageFour