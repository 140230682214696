import styled from "styled-components"
import { useState } from 'react'
import { CustomButton } from "../../../components/Button/Button.styles"


const NavCard = styled.div`
width: 100%;
height: 85px;
background: #FFFFFF;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 6px;
padding: 20px;
display: flex;
justify-content: space-between;
align-items: center
`
const NavCardTitle = styled.div`
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
color: #042A2B;
`


const SavingsPage = props => {
    return (
        <>
       <NavCard>
            <NavCardTitle>Savings</NavCardTitle>
            <div>
                <CustomButton style={{paddingRight: "20px", paddingLeft: "20px"}} color={'#424CA0'}>New Savings Plan</CustomButton>
            </div>
    </NavCard>

        </>

    )
}
export default SavingsPage