import SideDisplay from "../../components/SideDisplay"
import { Container, Col, Row } from 'react-bootstrap'
import Logo from '../../assets/pngs/lightLogo.png'
import styled from "styled-components"
import { CustomButton } from "../../components/Button/Button.styles"
import Arrowhead from "../../assets/pngs/arrow-head.png"
import Luxury from "../../assets/pngs/luxury.png"
import BackArrow from "../../assets/pngs/backArrow.png"
import { useState } from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
const Content = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%
`
const MainContent = styled.div`
padding: 10%;
height: 100%;

`
const ContentDesc = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 20px;
color: #3E3E3E;
width: 50%;
margin-top: 30px;
`

const Header = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #5EB1BF
`

const Tabletext = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #042A2B;
`

const Label = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
margin-top: 30px;
margin-bottom: 30px;
color: #042A2B
`

const PageSeven = props => {
    const [radioValue, setRadioValue] = useState('2');
    const handleChange = (val) => setRadioValue(val);
    const radios = [
        { name: 'Daily', value: '1' },
        { name: 'Weekly', value: '2' },
        { name: 'Monthly', value: '3' },
      ];

    return (
        <>
        <Row style={{height: "100vh", width: "100vw"}}>
            <Col md={5} style={{background: '#FFF8F5'}}>
                    <Content>
                     <img src={Luxury} style={{height:"450px"}} />
                     <ContentDesc>
                     Withdrawals can only be made after the maturity period on March 25, 2021
                     </ContentDesc>
                     </Content>
            </Col>
            <Col md={7}>
                    <MainContent>
                        <div style={{ marginTop: "10%",}}>
                            <CustomButton color="#FFFFFF">
                            <img src={BackArrow} />
                             <span style={{color: "#000000", fontWeight:"bold"}}>  Back</span>
                            </CustomButton>
                    <div style={{ marginTop: "5%"}} >
                        <div>Transaction Summary</div>
                        <Label>Malaysia Vacation</Label>
                            <Row>
                                <Col md={6}><Header>Savings Amount </Header></Col>
                                <Col md={6}><Header>Savings Period</Header></Col>
                                <Col md={6}><Tabletext>₦500</Tabletext></Col>
                                <Col md={6}><Tabletext>Weekly</Tabletext></Col>
                            </Row>
                            <Row style={{marginTop: "30px", marginBottom: "30px"}}>
                                <Col md={6}><Header>Starting Date</Header></Col>
                                <Col md={6}><Header>Maturity Date</Header></Col>
                                <Col md={6}><Tabletext>December 25, 2020</Tabletext></Col>
                                <Col md={6}><Tabletext>March 25, 2021</Tabletext></Col>
                            </Row>
                           <Row>
                                <Col md={12}><Header>Interest Rate p.a</Header></Col>
                                <Col md={12}><Tabletext>3.5%</Tabletext></Col>
                            </Row>
                    </div>  
                    </div>
                    <div style={{marginTop: "20%"}}>
                        <Link to={'/dashboard'}>
                        <CustomButton style={{padding: "10px", width: "150px"}} color={'#000000'} > LINK CARD <img src={Arrowhead} /> </CustomButton>
                    </Link></div>
                     </MainContent>
            </Col>
        </Row>  
        </>
    )
}

export default PageSeven