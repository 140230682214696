
import React from 'react'
import styled from "styled-components"
import { Container, Col, Row, Carousel } from 'react-bootstrap'
import {white, greyTwo, grey } from '../../../utils/UITheme/UITheme'
import quote from '../../../assets/pngs/quote.png'

const TopLayerContainer = styled(Container)`
 height: 300px;
 margin-top: 100px
`

const TopLayerHeaderText = styled.div`
font-family: ProductSansBold;
font-size: 35px;
text-align: center;
`

const TopLayerSubText = styled.div`
font-size: 25px;
text-align: center;
color: ${grey};
`
const SecondLayerContainer = styled.div`
height: 600px;
padding-top: 50px;
padding-right: 20px;
padding-left: 20px
`

const CarouselItemContainer = styled.div`
width: 585px;
height: 383px;
background: ${greyTwo};
border-radius: 10px;
`

const Quote = styled.div`
font-family: Roboto;
font-style: italic;
font-size: 18px;
text-align: center;
color: ${white};
width: 80%
`

const SubQuote = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
text-align: center;
color: ${white};
`

const TestimonySection = props => {

    return (
        <>
        <TopLayerContainer fluid>
            <Row style={{height: "100%"}} className="d-flex justify-content-center align-items-center">
            <Col sm={12} md={4}>
            <TopLayerHeaderText>The habit of saving is an education</TopLayerHeaderText>
            <TopLayerSubText>Be inspired! let our success stories guide you</TopLayerSubText>
            </Col>
            </Row>
        </TopLayerContainer>
        <SecondLayerContainer fluid>
        <Carousel controls={false} indicators={false}>
  <Carousel.Item style={{ display: "flex", justifyContent: "center", width: "100%"}} >
      <CarouselItemContainer >
          <div style={{height: "90%"}} className="d-flex flex-column justify-content-between align-items-center">
          <div style={{paddingTop: "10%"}}>
              <img src={quote} />
          </div>

          <Quote>
            Using ChipTranz has really helped me in 
            achieving my dreams in the most unexpected way.
          </Quote>
          <SubQuote style={{paddingBottom: "2%"}}>
            Ibrahim Adeola, Student, University of Ibadan
          </SubQuote>
          </div>
      </CarouselItemContainer>
  </Carousel.Item>

</Carousel>
        </SecondLayerContainer>
        </>
    )

}

export default TestimonySection