import SideDisplay from "../../components/SideDisplay"
import { Container, Col, Row } from 'react-bootstrap'
import Logo from '../../assets/pngs/lightLogo.png'
import {Link} from 'react-router-dom'
import styled from "styled-components"
import { CustomButton } from "../../components/Button/Button.styles"
import Arrowhead from "../../assets/pngs/arrow-head.png"
const SideContainer = styled.div`
height: 100%;
background: #E45200;
`

const Text = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 50px;
color: #FFFFFF;
`

const Content = styled.div`
padding: 10%
`

const TopLabel = styled.div`
width: 100%;
height: 29px;
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
color: #042A2B;
`

const MainContent = styled.div`
padding: 10%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`

const CustomInput = styled.input`
width: 80%;
height: 50px;
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 2px solid black;
margin-top: 10% ;
margin-bottom: 5px
`

const Desc = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #B9B9B9;
`

const PageOne = props => {
    return (
        <>
        <Row style={{height: "100vh", width: "100vw"}}>
            <Col md={5} style={{background: '#E45200'}}>
                    <Content>
                     <img src={Logo} style={{height: "28px"}} />
                     <div style={{paddingTop: "30%"}}>
                     <Text>Youth Goals</Text>
                     <Text>Savings Plan</Text>
                     </div>
                     </Content>
            </Col>
            <Col md={7}>
                    <MainContent>
                     <TopLabel>
                         Enter a name for your youth goal account *
                    </TopLabel>
                    <div style={{marginBottom: "5%"}}>
                         <CustomInput placeholder="e.g Vacation in Dubai"></CustomInput>
                     </div>
                     <Desc>
                     A savings plan helps you save periodically. You can use a fun
                     </Desc>
                    
                    <div style={{marginTop: "20%"}}>
                        <Link to={"/plan/create/2"}>
                        <CustomButton style={{padding: "10px", width: "150px"}} color={'#000000'} > Next <img src={Arrowhead} /> </CustomButton>
                        </Link>
                    </div>
                     
                     </MainContent>
                     
            </Col>
        </Row>  
        </>
    )
}

export default PageOne