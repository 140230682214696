import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import banner from "../../../assets/pngs/banner.png"
import macbook from "../../../assets/pngs/macbook.png"
import CustomNavbar from  "../../../components/NavBar"


 const BannerContainer = styled(Container)`
    background:  url(${banner}) no-repeat;
    background-size: cover;
    width: 100%;
    height: 910px;
`
const HeaderText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 3.5rem;
color: #FFFFFF;
line-height: 70px;
padding-top: 30px
`

const SubText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: 300;

color: #FFFFFF;
padding-top: 30px;
font-size: 1.7rem;
padding-right: 10px;
`
const Header = props => {
    return (
        <> 
        <BannerContainer fluid>
        <CustomNavbar/>
            <Row style={{height: "35%"}}></Row>   
            <Row>
                <Col md={1}></Col>
                <Col md={5}>
                    <Row>
                        <Col md={10}>
                        <HeaderText>Building a great future is a priority</HeaderText>
                    <SubText>With a Chiptranz account, you have a perfect control of your money</SubText>
                        </Col>
                    </Row>
                    
                </Col>
                <Col md={5} style={{background: ""}}>
                <div style={{padding: "30px"}}>
                <img fluid width="600px" height="auto" src={macbook} />
                </div>
                </Col>
                <Col md={1}></Col>
            </Row>
        </BannerContainer>
    </>
        // <div>
        //     <div>
        //         <img fluid style={{ height: "608px", width: "100%" }} src={banner} />
        //     </div>
        // </div>
    )
}

export default Header