import React from 'react'
import styled from "styled-components"
import { Container, Col, Row} from 'react-bootstrap'
import CustomNavbar from '../../components/NavBar'
import ImgOne from '../../assets/pngs/retireImg.png'
import ImgTwo from '../../assets/pngs/save.png'
import dots from '../../assets/svgs/dots.svg'
import { CustomButton as Button } from '../../components/Button/Button.styles'
import {grey, orange} from '../../utils/UITheme/UITheme'
import IconOne from '../../assets/svgs/charityIcon.svg'
import IconTwo from '../../assets/svgs/financeIcon.svg'
import Footer from '../../sections/LandingPage/footer'
// import banner from "../../../assets/pngs/banner.png"
// import macbook from "../../../assets/pngs/macbook.png"
// import CustomNavbar from  "../../../components/NavBar"


const TopContainer = styled(Container)`
width: 100%;
height: 469px;
background: #424CA0;
`
const TopTextContainer= styled.div`
width: 100%;
height: 100%;
`

const TopTitle= styled.div`
font-weight: bold;
font-size: 40px;
color: #FFFFFF;
width: 100%;
`

const TopSubTitle= styled.div`
color: #FFFFFF;
width: 100%;
font-size: 20px;
`

const Desc = styled(Container)`
background-color: #E9F6FF;
height: 399px;
margin-bottom: 10px
`
const TextArea = styled.div`
margin-left: 10px;
`
const Title = styled.div`
font-family: 'ProductSansBold';
font-size: 25px;
color: #000000;
line-height: 30px;
padding-bottom: 10px
`
const Content = styled.div`
font-size: 14px;
color: #454545;
`

const Card = styled.div`
width: 250px;
height: 378px;
background: #FFFFFF;
box-shadow: 0px 1px 4px #D7D7D7;
border-radius: 6px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding: 25px;
text-align: center
`


const SavingsInvPage = props => {
    return (
        <div>
            <TopContainer className="d-flex flex-column" fluid>
                <CustomNavbar />
                <TopTextContainer className="d-flex  align-items-center">
                    <Row className="w-100">
                        <Col md={1}></Col>
                        <Col md={10}>
                        <TopTitle>Savings Solution</TopTitle>
                        <TopSubTitle>Choose from one our savings goals.</TopSubTitle>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </TopTextContainer>
            </TopContainer>
            <Desc fluid>
                <Row style={{ height: "100%" }}>

                    <Col md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col className="d-none d-md-flex" md={3} style={{ display: 'flex', paddingLeft: '60px', alignItems: "center" }} >
                                <img style={{ height: "60px" }} src={dots} />
                            </Col>
                            <Col md={9}>
                            <Row>
                                <Col sm={12} md={8}><Title>Youth Goals</Title> 
                                </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={8} > <Content>Thinking of a savings plan that can enable you save more and spend less for your set goals. The ChipTranz Youth Goals plan is what you need. This plan allow for automated savings which can be daily, weekly or monthly. It is suitable for young adults with the intention of achieving a future goal. With as low as NGN500, you can automate your saving and expect an impressive interest rate on your savings. Your money can be liquidated after 3 months.
                               <div style={{ marginTop: "10px" }}><Button style={{background: `${orange}`, width:"150px"}} primary>Get Started</Button></div>
                                </Content> </Col> </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="d-none d-md-flex" md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col md={10} style={{ display: 'flex', justifyContent: "center", allignItems: "center" }}>
                                <img fluid style={{ width: "300px" }} src={ImgTwo} />
                            </Col>
                            <Col md={2} style={{}}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Desc >

            <Desc fluid>
                <Row style={{ height: "100%" }}>

                    <Col md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col className="d-none d-md-flex" md={3} style={{ display: 'flex', paddingLeft: '60px', alignItems: "center" }} >
                                <img style={{ height: "60px" }} src={dots} />
                            </Col>
                            <Col md={9}>
                            <Row>
                                <Col sm={12} md={8}><Title>Steady Growth</Title> 
                                </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={8} > <Content>The Steady Growth Account is designed suitable for inconsistent income earners to cultivate a saving culture. With the steady growth category, you can automatically save desired amounts daily, weekly or monthly. It is suitable for lump sum of money that can yield higher interest rather than being at the bank. With as low as NGN500, you can automate your saving to expect an impressive interest rate per annum on your savings. It can be saved for six (6) months or more.
                               <div style={{ marginTop: "10px" }}><Button style={{background: `${orange}`, width:"150px"}} primary>Get Started</Button></div>
                                </Content> </Col> </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="d-none d-md-flex" md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col md={10} style={{ display: 'flex', justifyContent: "center", allignItems: "center" }}>
                                <img fluid style={{ width: "300px" }} src={ImgOne} />
                            </Col>
                            <Col md={2} style={{}}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Desc >
            <div class="d-flex align-items-center justify-content-center" style={{height: "700px"}}>
                    <Row className="d-flex justify-content-center">
                <Card style={{marginLeft: "15px"}}>
                <img src={IconOne} />
                <div>
                <div style={{fontSize: "14px", fontWeight: "bold", padding: "10px"}}>
                    Youth Goals
                </div>
                <div>
                Start a savings plan that helps you achieve all your big plans
                </div>
            </div>
            <div style={{color: "#A2BCC3", fontSize: '11px'}}>
            Some features
            </div>
            <div style={{fontSize: "13px", color: "black", padding: "20px"}}>
            10% Interest per annum Flexible savings plan Liquidation after 6 months 
            </div>
            </Card>
            <Card style={{marginLeft: "15px"}}>
                <img src={IconTwo} />
                <div>
                <div style={{fontSize: "15px", fontWeight: "bold", padding: "10px"}}>
                    Youth Goals
                </div>
                <div>
                Start a savings plan that helps you achieve all your big plans
                </div>
            </div>
            <div style={{color: "#A2BCC3", fontSize: '11px'}}>
            Some features
            </div>
            <div style={{fontSize: "13px", color: "black", padding: "20px"}}>
            10%-15% Interest per annum Fixed savings plan Liquidation after 12 months
            </div>
            </Card>
            </Row>
            </div>
            <TopContainer className="d-flex flex-column" fluid>
                <TopTextContainer className="d-flex justify-content-center align-items-center">
                    <Row className="w-100">
                        <Col md={1}></Col>
                        <Col md={10}>
                        <TopTitle style={{textAlign: "center"}}>Secure your future today</TopTitle>
                        <div className="d-flex justify-content-center">
                        <Button style={{background: `${orange}`, width:"150px", marginRight: "10px"}}>Get Started</Button>
                        <Button style={{background: `${grey}`, width:"150px", marginLeft: "10px"}}>Sign In</Button>
                        </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </TopTextContainer>
            </TopContainer>
            <Footer />
        </div>
    )
}


export default SavingsInvPage