import {Button } from 'react-bootstrap'
import styled from "styled-components"


const SideBarBtn = styled(Button)`
width: 200px;
height: 40px;
border-radius: 25px;
`
const CustomIconHolder = styled.div`
paddingRight: 20px; 
position: absolute;
display: flex;
align-items: center;
padding-left: 10px
`

const SidebarButton =({text, lightIcon, darkIcon, status}) => {
return (
    <SideBarBtn>
                <CustomIconHolder>
                <img style={{paddingRight: "20%"}} src={darkIcon } />
                </CustomIconHolder>
               {text}
    </SideBarBtn>
)
    
}

export default SidebarButton