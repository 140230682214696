import React from 'react'
import styled from "styled-components"
import { Container, Col, Row, Carousel } from 'react-bootstrap'
import {white, greyTwo, grey } from '../../../utils/UITheme/UITheme'
import ovalshape from '../../../assets/pngs/ovalshape.png'
import one from '../../../assets/svgs/one.svg'
import two from '../../../assets/svgs/two.svg'
import three from '../../../assets/svgs/three.svg'
import four from '../../../assets/svgs/four.svg'
const HowSectionContainer = styled(Container)`
height: auto;
margin-top: 150px;
margin-bottom: 150px
`
const HowTextHeader = styled.div`
font-size: 35px;
`
const HowSubText = styled.div`
font-size: 14px;
color: #454545
`
const CardContainer = styled.div`
width: 158px;
height: 158px;
background: ${props => props.color};
border-radius: 10px;
postion: absolute;
top: 10%;
`

const Shape = styled.div`
background: url(${ovalshape});
background-repeat: no-repeat;
width: 472px;
height: 365px;
background-size: contain;
`

const CardText = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: 300;
font-size: 16px;
color: ${props => props.color};
`
const HowSection = props => {
    return(
        <>
        <HowSectionContainer fluid >
        <Row style={{height:"100%"}}>
            <Col xs={{ order: 'last', span: 12 }} md={{span: 6, order: "first"}} style={{display: "flex",  justifyContent: "center", alignItems: "center"}}>
             <Shape style={{ display: "flex", flexDirection: "row", justifyContent: "center"}}>
                 <div style={{paddingRight: "15px"}}>
                 <CardContainer color="#FFF2F2" style={{marginBottom: "10px"}}>
                    <div style={{padding: "10px"}}>
                    <img width="35px"  src={one} />
                    <CardText color="#610000">
                    Sign up for ChipTranz account
                    </CardText>
                    </div>
                 </CardContainer>
                 <CardContainer color="#FFF4EB">
                 <div style={{padding: "10px"}}>
                    <img width="35px"  src={three} />
                    <CardText color="#7C3800">
                    Save smart by saving continuosly for a period of 6 months
                    </CardText>
                    </div>
                 </CardContainer>
                 </div>
                 <div>
                 <CardContainer color="#F6E7FF" style={{marginTop: "40px", marginBottom: "10px"}}>
                 <div style={{padding: "10px"}}>
                    <img width="35px"  src={two} />
                    <CardText color="#5D036B">
                    Start a savings plan that help automate your savings
                    </CardText>
                    </div>
                 </CardContainer>
                 <CardContainer color="#EBFFFA">
                 <div style={{padding: "10px"}}>
                    <img width="35px"  src={four} />
                    <CardText color="#003F30">
                    Start a savings plan that help automate your savings
                    </CardText>
                    </div>
                 </CardContainer>
                 </div>
              </Shape> 
              
            </Col>
            <Col xs={{ order: 'first', span: 12 }} md={{span: 6, order: "last"}} >
                <Row style={{height:"100%", paddingBottom: "50px"}} className="d.flex align-items-center ">
                    <Col md={5} style={{ width: "100%"}} className="d.flex justify-content-center " >
                    <HowTextHeader>
                    How it works
                </HowTextHeader>
                <HowSubText >
                With the right mindset you can easily grow your savings, simply follow our 5 easy steps and your money will work as hard as you do.
                </HowSubText>
                    </Col>
                </Row>
                
            </Col>
        </Row>
        </HowSectionContainer>
        </>
    )
}


export default HowSection