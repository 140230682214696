import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import logo from '../../../assets/pngs/logo.png'
import gmail from '../../../assets/pngs/gmail.png'
import instagram from '../../../assets/pngs/instagram.png'
import linkedin from '../../../assets/pngs/linkedin.png'
import facebook from '../../../assets/pngs/facebook.png'
import twitter from '../../../assets/pngs/twitter.png'

const FooterContainer = styled(Container)`
margin-top: 200px;
margin-bottom: 100px
`
const Title = styled.div`
font-family: Roboto;
font-weight: 700;
font-weight: normal;
font-size: 14px;
color: #000000;
`

const Text = styled.div`
font-family: Roboto-Light;
font-weight: 300;
font-size: 14px;
color: #000000;
padding-top: 5px
`

const TextGroup = styled.div`
padding-Top: 10px
`


const Footer = props => {
    return (
        <FooterContainer fluid>
            <Row>
                <Col md={1}></Col>
                <Col style={{paddingBottom: "20px"}}  className="d-flex" sm={12} md={2}>
                   <div> <img src={logo} /></div>
                </Col>
                <Col xs={6} md={2}>
                <Title>PRODUCTS</Title>
                <TextGroup>
                    <Text>Youth Goals</Text>
                    <Text>Steady Growth</Text>
                </TextGroup>
                </Col>
                <Col xs={6} md={2}>
                <Title>COMPANY</Title>
                <TextGroup>
                    <Text>About Us</Text>
                    <Text>FAQs</Text>
                </TextGroup>
                </Col>
                <Col xs={6} md={2}>
                <Title>LEGAL</Title>
                <TextGroup>
                    <Text>Terms of Service</Text>
                    <Text>Privacy Policy</Text>
                </TextGroup>
                </Col>
                <Col xs={6} md={2}>
                <Title>CONTACT</Title>
                <TextGroup>
                    <Text>Plot 5, Penel Avenue, Balogun Area, Ibadan, +2349012697371</Text>
                
                </TextGroup>
                </Col>
                <Col md={1}></Col>
            </Row>

            <Row style={{marginTop: "70px"}}>
                <Col className="d-none d-md-block " md={1}></Col>
                <Col md={10}>
                    <Row>
                    <Col   md={{order: 'first', span:8 }} xs={{order: 'last', span:12}}><Text>(c) Copyright 2020 | Chiptranz Integrated System</Text></Col>
                     <Col  md={{order: 'last', span:4 }} xs={{order: -1, span:12}}>
                    <div className="d-flex justify-content-md-end">
                    <div> <img src={facebook} /></div>
                    <div> <img src={twitter} />  </div>
                    <div><img src={instagram} /></div>
                    <div><img src={linkedin} /></div>
                    <div> <img src={gmail} /></div>
                    </div>
                </Col>
                    </Row>
                </Col>
                
                <Col className="d-none d-md-block " md={1}></Col>
            </Row>

        </FooterContainer>
    )
}

export default Footer 