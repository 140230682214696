import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import logo from '../../../assets/pngs/logo.png'
import {CustomButton} from '../../../components/Button/Button.styles'
import {Link} from 'react-router-dom'
import {AntdTabs} from '../../../components/CustomTabs/CustomTabs'


const { TabPane } = AntdTabs;


const LogoArea = styled.div`
`
const CustomInput = styled.input`
height: 50px;
background: #FFFFFF;
border: 1px solid #042A2B;
box-sizing: border-box;
padding: 10px;
margin: 10px
`
const CustomBtn = styled(CustomButton)`
Background: black;
&:hover, &:focus, &:visited, &:disabled, &:active{
    background-color: black;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    transition: box-shadow 700ms;
};
width: 300px
`

const Register = props => {
    return (
        <>
        <Row className="d-flex flex-column justify-content-center align-items-center">
            <Link to="/">
                <Col md={12} style={{textAlign: "center", paddingTop: "50px", paddingBottom: "50px"}}>
                <img src={logo} />
                </Col>
            </Link>
            <Col md={12}>
            <AntdTabs defaultActiveKey="1" centered>
                <TabPane tab="Account info" key="1">
                <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                <Row style={{background: "", width: "100%", height: "100%"}} className="d-flex flex-column justify-content-center align-items-center">
                <Col style={{background: "", width: "100%", textAlign: "center"}} md={5}  >
                    <div style={{padding: "30px",  textAlign: "center"}}>
                <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>We’re glad you want to join chiptranz.</div>
                <div>Let’s start by creating your account</div>
                </div>
                <div className="d-flex flex-column">
                <CustomInput placeholder="Email Address" style={{width: "100%"}} />
                <Row >
                    <Col md={6}>
                    <CustomInput style={{width: "100%"}} placeholder="Password" />
                    </Col>
                    <Col md={6}><CustomInput style={{width: "100%"}} placeholder="Confirm Password" /></Col>
                </Row>
                </div>
                <div style={{margin: "30px"}}>
                    <CustomBtn>Personal Info</CustomBtn>
                </div>
                <div>
                You’ve create an account before? <Link to="/login" style={{color: "#5EB1BF"}}>Sign In</Link> 
                </div>
                </Col>
                </Row>
            </Col>
                </TabPane>
                <TabPane tab="Personal Info" key="2">
                <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                <Row style={{background: "", width: "100%", height: "100%"}} className="d-flex flex-column justify-content-center align-items-center">
                <Col style={{background: "", width: "100%", textAlign: "center"}} md={5}  >
                    <div style={{padding: "30px",  textAlign: "center"}}>
                <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>Identification details</div>
                <div>Enter your name and other details to personalize your experience</div>
                </div>
                <div className="d-flex flex-column">
                <Row >
                    <Col md={6}>
                    <CustomInput style={{width: "100%"}} placeholder="First Name" />
                    </Col>
                    <Col md={6}><CustomInput style={{width: "100%"}} placeholder="Last Name" /></Col>
                </Row>
                <CustomInput placeholder="Occupation" style={{width: "100%"}} />
                </div>
                <div style={{margin: "30px"}} className="d-flex justify-content-around">
                <CustomBtn>Personal Info</CustomBtn>
                <CustomBtn>Submit & Verify</CustomBtn>
                </div>
                <div>
                You’ve create an account before? <Link to="/login" style={{color: "#5EB1BF"}}>Sign In</Link> 
                </div>
                </Col>
                </Row>
            </Col>
        </Row>
                </TabPane>
                <TabPane tab="Submit & Verify" key="3">
                <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                <Row style={{background: "", width: "100%", height: "100%"}} className="d-flex flex-column justify-content-center align-items-center">
                <Col style={{background: "", width: "100%", textAlign: "center"}} md={5}  >
                    <div style={{padding: "30px",  textAlign: "center"}}>
                <div style={{fontWeight: "bold", fontSize: "20px", textAlign: "center"}}>Okay, Lets wrap it up quickly</div>
                <div>We’ve sent a code to your email address, kindly enter the code below</div>
                
                </div>
                <div className="d-flex flex-column">
                <Row >
                    <Col md={6}>
                    <CustomInput style={{width: "100%"}} placeholder="First Name" />
                    </Col>
                    <Col md={6}><CustomInput style={{width: "100%"}} placeholder="Last Name" /></Col>
                </Row>
                <CustomInput placeholder="Occupation" style={{width: "100%"}} />
                </div>
                <div style={{margin: "30px"}} className="d-flex justify-content-around">
                <input id="partitioned" type="text" maxlength="4" />
                </div>
                <div style={{margin: "30px"}} className="d-flex justify-content-around"><CustomBtn>Submit</CustomBtn></div>
                <div>You’ve create an account before? <Link to="/login" style={{color: "#5EB1BF"}}>Sign In</Link></div>
                </Col>
                </Row>
            </Col>
        </Row>
                </TabPane>
            </AntdTabs>
            </Col>
        </Row>
        </>
    )
}

export default Register