import React from 'react'
import styled from "styled-components"
import CustomNavbar from '../../components/NavBar'
import { Container, Col, Row} from 'react-bootstrap'
import { CustomButton as Button } from '../../components/Button/Button.styles'
import {grey, orange} from '../../utils/UITheme/UITheme'
import {Link} from 'react-router-dom'
import Plus from '../../assets/svgs/plus.svg'
import Minus from '../../assets/svgs/minus.svg'
import Footer from '../../sections/LandingPage/footer'

const TopContainer = styled(Container)`
width: 100%;
height: 469px;
background: #424CA0;
`
const TopTextContainer= styled.div`
width: 100%;
height: 100%;
`
const TopTitle= styled.div`
font-weight: bold;
font-size: 40px;
color: #FFFFFF;
width: 100%;
`
const TopSubTitle= styled.div`
color: #FFFFFF;
width: 100%;
font-size: 20px;
`

const QuestionContainer = styled(Container)`
height: 700px;
`

const FaqPage = props => {
    return ( 
        <>
             <TopContainer className="d-flex flex-column" fluid>
                <CustomNavbar />
                <TopTextContainer className="d-flex  align-items-center">
                    <Row className="w-100">
                        <Col md={1}></Col>
                        <Col md={10}>
                        <TopTitle>Frequently Asked <br></br> Questions</TopTitle>
                        <TopSubTitle>We know you have some questions to ask, <br></br>go through some of our often asked questions</TopSubTitle>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </TopTextContainer>
            </TopContainer>

            <QuestionContainer fluid>
                <Row className="m-5">
                    <Col md={1}></Col>
                    <Col md={10}>
                        <Row className="pb-2">
                            <Col md={10}>How do I sign up?</Col>
                            <Col md={2}><img src={Plus} /></Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={10}>How do I access my account information?</Col>
                            <Col md={2}><img src={Plus} /></Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={10}>How much can I save on Chiptranz?</Col>
                            <Col md={2}><img src={Plus} /></Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={10}>What types of savings and investment plans are available on Chiptranz?</Col>
                            <Col md={2}><img src={Plus} /></Col>
                        </Row>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </QuestionContainer>

            <TopContainer className="d-flex flex-column" fluid>
                <TopTextContainer className="d-flex justify-content-center align-items-center">
                    <Row className="w-100">
                        <Col md={1}></Col>
                        <Col md={10}>
                        <TopTitle style={{textAlign: "center"}}>Take control</TopTitle>
                        <TopSubTitle style={{textAlign: "center"}}>Budgeting, saving and earning with one ChipTranz account. <br></br> We’d love to help you take control over your money.</TopSubTitle>
                        <div className="d-flex justify-content-center" style={{marginTop: "20px"}}>
                        <Button to="/login" style={{background: `${orange}`, width:"150px", marginRight: "10px"}}>Get Started</Button>
                        </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </TopTextContainer>
            </TopContainer>
            <Footer />
        </>
    )
}

export default FaqPage