import React from 'react'
import styled from "styled-components"
import { Container, Col, Row } from 'react-bootstrap'
import { CustomButton as Button } from '../../../components/Button/Button.styles'
import spendImgOne from '../../../assets/pngs/spendImgOne.png'
import dots from '../../../assets/svgs/dots.svg'
import spendImgTwo from '../../../assets/pngs/spendImgTwo.png'
import spendImgThree from '../../../assets/pngs/spendImgThree.png'



const Desc = styled(Container)`
background-color: #E9F6FF;
height: 399px;
margin-bottom: 10px;
`
const TextArea = styled.div`
margin-left: 10px;
`
const Title = styled.div`
font-family: 'ProductSansBold';
font-size: 25px;
color: #000000;
line-height: 30px;
padding-bottom: 10px
`
const Content = styled.div`
font-size: 14px;
color: #454545;
`


const DescriptionSection = props => {
    return (
        <>
            <Desc fluid>
                <Row style={{ height: "100%" }}>

                    <Col md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col className="d-none d-md-flex" md={3} style={{ display: 'flex', paddingLeft: '60px', alignItems: "center" }} >
                                <img style={{ height: "60px" }} src={dots} />
                            </Col>
                            <Col md={9}>
                            <Row>
                                <Col sm={12} md={8}><Title>Always make sure you spend on priorities</Title> 
                                </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={8} > <Content>Spending can be trivial when you have money in your pocket.
                                Use our budgeting tool to plan your expenses so that you don’t spend on trivialities.
                               <div style={{ marginTop: "10px" }}><Button primary>Learn More</Button></div>
                                </Content> </Col> </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="d-none d-md-flex" md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col md={10} style={{ display: 'flex', justifyContent: "center", allignItems: "center" }}>
                                <img fluid style={{ width: "300px" }} src={spendImgOne} />
                            </Col>
                            <Col md={2} style={{}}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Desc >

            <Desc fluid>
                <Row style={{ height: "100%" }}>
                    <Col className="d-none d-md-flex" md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row  >
                            <Col md={3} style={{ display: 'flex', paddingLeft: '60px', alignItems: "center" }} >
                                <img style={{ height: "60px" }} src={dots} />
                            </Col>

                            <Col md={9}>
                                <img fluid style={{ paddingLeft: "30px", width: "300px" }} src={spendImgTwo} />
                            </Col>
                        </Row>

                    </Col>
                    <Col md={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col md={2}>
                            </Col>
                            <Col md={10}>
                                <Row>
                                <Col sm={12} md={8}>
                                <Title>Set your goals and save toward it automatically</Title>
                                </Col>
                                </Row>
                               
                                
                                <Row>
                                    <Col sm={12} md={8} > 
                                   <Content> You may not see it today but we want you to look back in a few years and be awed by how every little of your savings added up and brought you where you wanted to be. Use our youth friendly accounts to track your savings as it grow bit-by-bit.
                               <div style={{ marginTop: "10px" }}><Button primary>Learn More</Button></div> </Content>
                                    </Col>
                                </Row>
                                   
                               
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Desc>
            <Desc fluid>
                <Row style={{ height: "100%" }}>

                    <Col  md={7} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col className="d-none d-md-flex" md={3} style={{ display: 'flex', paddingLeft: '60px', alignItems: "center" }} >
                                <img style={{ height: "60px" }} src={dots} />
                            </Col>
                            <Col md={9}>
                            <Row>
                                <Col sm={12} md={8}>
                                <Title>Your future is secured</Title>  </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={8} > 
                                <Content>We went a long way toward ensuring that your money is secured through partnership with leading financial management services and payment solutions like Meristem Securities Limited and Paystack Payment Limited.
                               <div style={{ marginTop: "10px" }}><Button primary>Learn More</Button></div>
                                </Content>   </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col  className="d-none d-md-flex" md={5} style={{ display: 'flex', flexDirection: "column", justifyContent: "center", allignItems: "center" }}>
                        <Row>
                            <Col md={10} style={{ display: 'flex', justifyContent: "center", allignItems: "center" }}>
                                <img fluid style={{ width: "300px" }} src={spendImgThree} />
                            </Col>
                            <Col md={2} style={{}}></Col>
                        </Row>
                    </Col>
                </Row>
            </Desc >
        </>
    )

}

export default DescriptionSection
