import SideDisplay from "../../components/SideDisplay"
import { Container, Col, Row } from 'react-bootstrap'
import Logo from '../../assets/pngs/lightLogo.png'
import styled from "styled-components"
import { CustomButton } from "../../components/Button/Button.styles"
import Arrowhead from "../../assets/pngs/arrow-head.png"
import Abstract from "../../assets/pngs/abstract.png"
import BackArrow from "../../assets/pngs/backArrow.png"
import Naira from "../../assets/svgs/naira.svg"
import { useState } from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
const Content = styled.div`
display: flex;
justify-content: center;
align-items: flex-end;
height: 100%;
padding-bottom: 10%
`
const TopLabel = styled.div`
width: 100%;
height: 29px;
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
color: #042A2B;
`
const MainContent = styled.div`
padding: 10%;
height: 100%;

display: flex;
flex-direction: column;
justify-content: space-between
`

const CustomInput = styled.input`
 ::placeholder { 
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 35px;
color: #D8D8D8; 
  };
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #000000; 
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 2px solid #FFE8C4;
width: 80%
`

const SpecialInput = styled.div`
display: flex;
flex-direction: column;
`
const InputContent = styled.div`
display: flex;
flex-direction: row;
`
const Prefix = styled.div`
display: flex;
padding-right: 20px;
justify-content: center;
align-items: center;
font-family: Roboto;
font-size: 18px;
color: #8B8B8B;
`

const Label = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
color: #D8D8D8;
padding-bottom: 30px
`

const PageThree = props => {

    const [radioValue, setRadioValue] = useState('1');

    const handleChange = (val) => setRadioValue(val);

    const radios = [
        { name: 'Yes', value: '1' },
        { name: 'No, I will save when i want', value: '2' },
      ];

    return (
        <>
        <Row style={{height: "100vh", width: "100vw"}}>
            <Col md={5} style={{background: '#1AA0EC'}}>
                    <Content>
                     <img src={Abstract} style={{height:"300px"}} />
                     </Content>
            </Col>
            <Col md={7}>
                    <MainContent>
                        <div style={{ marginTop: "10%",}}>
                            <CustomButton color="#FFFFFF">
                            <img src={BackArrow} />
                             <span style={{color: "#000000", fontWeight:"bold"}}>  Back</span>
                            </CustomButton>
                    <div style={{ marginTop: "5%"}} >
                    <TopLabel style={{marginBottom: "30px"}}>
                    How much would you like to save into this account periodically?
                    </TopLabel>
                    <SpecialInput>
                        <Label>Enter Amount</Label>
                        <InputContent>
                            <Prefix><img src={Naira} /></Prefix>
                            <CustomInput placeholder="3,000"></CustomInput>
                        </InputContent>
                    </SpecialInput>

                    </div>  </div>
                    <div style={{marginTop: "20%"}}>
                        <Link to={"/plan/create/4"} >
                        <CustomButton style={{padding: "10px", width: "150px"}} color={'#000000'} > Next <img src={Arrowhead} /> </CustomButton>
                    </Link>
                    </div>
                     </MainContent>
            </Col>
        </Row>  
        </>
    )
}

export default PageThree