import styled from "styled-components";
import Logo from "../../assets/pngs/logo.png"
import Arrowdown from "../../assets/svgs/dashboard/arrowdown.svg"
import Contact from "../../assets/svgs/dashboard/contact.svg"

const DashboardNavBarContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 20px;

`

const AccountToggle = styled.div`
display: flex;
justify-content: center;
align-items: center
`

const DashboardNavBar = props => {

    return (
        
        <DashboardNavBarContainer>
        <div>
            <img src={Logo} />
        </div>
        <AccountToggle>
        <img src={Contact} style={{paddingRight: "10px"}} />
        <div>Grace</div>  
        <img src={Arrowdown} style={{paddingLeft: "10px"}} />
        </AccountToggle>
    </DashboardNavBarContainer>
    )
}


export default DashboardNavBar