import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';
import AuthPage from './pages/AuthPage';
import { Route, Switch, Redirect } from 'react-router-dom';
import GlobalStyle from './utils/UITheme/globalStyles';
import Login from './sections/AuthPage/Login';
import SavingsInvPage from './pages/SavingInvPage';
import FaqPage from './pages/FaqPage';
import PasswordReset from './sections/AuthPage/PasswordReset';
import Register from './sections/AuthPage/Registration';
import './App.css';
import PageOne from './pages/CreateSavings/pageOne';
import PageTwo from './pages/CreateSavings/pageTwo';
import PageThree from './pages/CreateSavings/pageThree';
import PageFour from './pages/CreateSavings/pageFour';
import PageFive from './pages/CreateSavings/pageFive';
import PageSix from './pages/CreateSavings/pageSix';
import PageSeven from './pages/CreateSavings/pageSeven';
import Summary from './pages/Dashboard/dashboardWrapper/DashboardWraper';
import DashboardWrapper from './pages/Dashboard/dashboardWrapper/DashboardWraper';
function App() {
  return (
    <>
      <GlobalStyle />
     <Switch>
       <Route exact path="/" component={LandingPage} />
       <Route exact path="/login_old" component={Login} />
       <Route path='/login' component={() => {
    window.location.href = 'http://app.chiptranz.com/login';
    return null;
}}/>
       <Route exact path="/password-reset" component={PasswordReset} />
       <Route exact path="/register" component={Register} />
       <Route exact path="/faqs" component={FaqPage} />
       <Route exact path="/savings-investment" component={SavingsInvPage} />
      <Route exact path="/plan/create/1" component={PageOne} />
      <Route exact path="/plan/create/2" component={PageTwo} />
      <Route exact path="/plan/create/3" component={PageThree} />
      <Route exact path="/plan/create/4" component={PageFour} />
      <Route exact path="/plan/create/5" component={PageFive} />
      <Route exact path="/plan/create/6" component={PageSix} />
      <Route exact path="/plan/create/7" component={PageSeven} />
      <Route  path="/dashboard" component={DashboardWrapper} />
      </Switch> 
    </>
  );
}

export default App;
