
import React from 'react'
import styled from "styled-components"
import { CustomButton } from '../../../components/Button/Button.styles'
import { Container, Col, Row } from 'react-bootstrap'
import { purple, white } from '../../../utils/UITheme/UITheme'

const CustomContainer = styled(Container)`
background-color: ${purple};
height: 399px;
margin-bottom: 10px;
`

const TitleText = styled.div`
font-family: Roboto-Black;
font-weight: bold;
font-size: 45px;
text-align: center;
color: ${white}
`

const Content = styled.div`
font-family: Roboto-Thin;
font-size: 20px;
text-align: center;
color: ${white};
margin-top: 10px;
`

const GetStarted = props => {
    return (
        <CustomContainer fluid>
            <Row style={{height: "100%"}}  className="justify-content-center">
                <Col className="d-flex flex-column align-items-center justify-content-center" sm={12} md={5}>
                <TitleText>Take control</TitleText>
            <Content>Budgeting, saving and earning with one ChipTranz account. We’d love to help you take control over your money.
            </Content>
            <div style={{textAlign:"center", marginTop: "10px"}}>
                 <CustomButton secondary>Get Started</CustomButton>
            </div>
                </Col>
            </Row>
            
        </CustomContainer>
    )
}

export default GetStarted